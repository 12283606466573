import useSWR from 'swr'

const API_URL = "/service/api/"

const fetcher = async (url,method,params) => {
    try {

        const token = localStorage.getItem('__token__')
        let headers = { 'content-type': 'application/json' }
        if (token) {
            headers = { ...headers, Authorization: token }
        }

        const response = await fetch(url, {
            method,
            headers: headers,
            ...params && {body: JSON.stringify(params) }   
        })
        const contentType = response.headers.get('content-type');

        if (contentType.includes('image/jpeg')) {
            return await response.blob()
        }

        if (contentType.includes('application/pdf')) {
            return await response.blob()
        }

        if (!contentType || !contentType.includes('application/json')) {
            throw new TypeError("Oops, we haven't got JSON!");
        }
        return await response.json();

    } catch (error) {
        console.log(error);
    }
}

const Client = (endpoint,method='GET',params) => {
    // console.log('swr:',endpoint && API_URL + endpoint)
    return useSWR(endpoint ? [endpoint && API_URL + endpoint,method,params] : null, fetcher)
}

export default Client