import React, { useState, useEffect } from 'react'
import * as authClient from '../utils/auth'
import Loading from '../components/loading'

const AuthContext = React.createContext()


function AuthProvider(props) {

  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {

    async function fetchUser() {
      setLoading(true);
      const response = await authClient.getUser();
      setLoading(false);
      setUser(response);
    }

    fetchUser();

  }, []);

  if (loading) return <Loading />

  const login = (params) => authClient.performLogin(params)
    .then(res => {
      if (res.user) setUser(res.user)
      else return res
    })
    .catch((err) => {
      console.log(err)
      return Promise.reject(null)
    })
    const loginGov = (params) => authClient.performLoginGov(params)
    .then(res => {
      if (res.user) setUser(res.user)
      else return res
    })
    .catch((err) => {
      console.log(err)
      return Promise.reject(null)
    })
    const loginAutarquia = (params) => authClient.performLoginAutarquia(params)
      .then(res => {
      if (res.AuUser) setUser({...user, AuUser: res.AuUser})
      else return res
    })
    .catch((err) => {
      console.log(err)
      return Promise.reject(null)
    })

  const reset = (params) => authClient.performReset(params)
    .then(res => {
      return res
    })
    .catch((err) => {
      console.log(err)
      return Promise.reject(null)
    })

  const setNewPassword = (params) => authClient.performSetNewPassword(params)
    .then(res => {
      return res
    })
    .catch((err) => {
      console.log(err)
      return Promise.reject(null)
    })

    const editPassword = (params) => authClient.performEditPassword(params)
    .then(res => {
      return res
    })
    .catch((err) => {
      console.log(err)
      return Promise.reject(null)
    })

  const register = (params) => authClient.performRegister(params)
    .then(res => {
      console.log("RESULT")
      console.log(res)
      if (res.user) setUser(res.user)
      else return res
    })
    .catch((err) => {
      console.log(err)
      return Promise.reject(null)
    })

  const logout = () => authClient.performlogout().then(setUser(null))

  const logoutAutarquia = () => {
    localStorage.removeItem('__AuUser__')
    setUser({...user,AuUser:null})
    return Promise.resolve()
  }

  return (
    <AuthContext.Provider value={{ user, login, loginAutarquia, reset, setNewPassword, register, logout,logoutAutarquia, editPassword, loginGov }} {...props} />
  )

}

function useAuth() {
  const context = React.useContext(AuthContext)
  if (context === undefined) {
    throw new Error(`useAuth must be used within a AuthProvider`)
  }
  return context
}

export { AuthProvider, useAuth }