import React from 'react';
import { ImpulseSpinner } from "react-spinners-kit"

export default function Loading() {

    return (
        <div className="flex justify-center mt-20">
            <ImpulseSpinner
                size={40}
                frontColor="var(--color500)" //#5692d0
                backColor="#edf2f7"
                loading={true}
            />
        </div>
    )

}
