import React, { lazy } from 'react';
import { useUser } from './context/user'
import Loading from './components/loading'
import Api from './api/base'
import { SnackbarProvider } from 'react-snackbar-alert';
import ThemeSwitcher from 'react-css-vars'
import { getColors } from 'theme-colors'

import './custom.css'

const AuthenticatedApp = lazy(() => import('./Authenticated'))
const UnauthenticatedApp = lazy(() => import('./Unauthenticated'))
const UnauthenticatedAppV2 = lazy(() => import('./Unauthenticatedv2'))
const UnauthenticatedAppV3 = lazy(() => import('./Unauthenticatedv3'))
const UnauthenticatedAppV4 = lazy(() => import('./Unauthenticatedv4'))



export default function App() {

    const user = useUser()

    const { data: mainColor } = Api('configs/public/main_color')
    const { data: layout } = Api('layout/active')

    if (!mainColor && !layout) return <Loading />
        
    const theme = getColors(mainColor?.data?.value || '#4299e1')
    const myTheme = {
        color50: theme['50'], 
        color100: mainColor?.data?.value ? theme['100'] : '#EBF8FF',
        color200: mainColor?.data?.value ? theme['200'] : '#BEE3F8',
        color300: mainColor?.data?.value ? theme['300'] : '#90CDF4',
        color400: mainColor?.data?.value ? theme['400'] : '#63B3ED',
        color500: mainColor?.data?.value ? theme['500'] : '#4299e1',
        color600: mainColor?.data?.value ? theme['600'] : '#3182CE',
        color700: mainColor?.data?.value ? theme['700'] : '#2B6CB0',
        color800: mainColor?.data?.value ? theme['800'] : '#2C5282',
        color900: mainColor?.data?.value ? theme['900'] : '#2A4365',
    }
    return (
        <ThemeSwitcher theme={myTheme}>
            <SnackbarProvider position="top" progressBar={false} timeout={1500}>
                <React.Suspense fallback={<Loading />}>
                    {user ? <AuthenticatedApp /> :(layout?.data?.version == 'v1' ? <UnauthenticatedApp /> : layout?.data?.version == 'v2' ? <UnauthenticatedAppV2/> : layout?.data?.version == 'v3' ? <UnauthenticatedAppV3/> : <UnauthenticatedAppV4/>)}
                    {/* {user ? <AuthenticatedApp /> :(layout?.data?.version == 'v1' ? <UnauthenticatedApp /> : layout?.data?.version == 'v2' ? <UnauthenticatedAppV2/> : '')} */}

                    {/* { user ? <AuthenticatedApp /> : <UnauthenticatedAppV3/>} */}
                    {/* {user ? <AuthenticatedApp /> : <UnauthenticatedApp /> } */}
                    {/* { user ? <AuthenticatedApp /> : <UnauthenticatedAppV4/>} */}

                </React.Suspense>
            </SnackbarProvider>
        </ThemeSwitcher>
    )

}

